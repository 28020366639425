/* SearchComponent.css */

:root {
    --primary-color: #4285f4;  /* Google blue */
    --secondary-color: #0738eb; /* Google green */
    --background-color: #f8f9fa; /* Light gray */
    --text-color: #202124; /* Almost black */
    --link-color: #4285f4; /* Blue for links */
    --link-hover-color: #3367d6; /* Darker blue on hover */
    --border-radius: 8px;
    --transition-speed: 0.3s;
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Roboto', Arial, sans-serif;
    line-height: 1.6;
}

.search-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

header, footer {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 15px 0;
    position: sticky;
    top: 0;
    z-index: 1000;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav button, nav select {
    padding: 8px 15px;
    margin: 0 5px;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
    color: var(--primary-color);
    cursor: pointer;
    transition: all var(--transition-speed);
}

nav button:hover, nav select:hover {
    background-color: var(--primary-color);
    color: white;
}

main {
    margin-top: 30px;
}

h1 {
    font-size: 28px;
    margin-bottom: 15px;
    color: var(--primary-color);
}

.veracite-message {
    color: var(--secondary-color);
    font-style: italic;
    margin-bottom: 20px;
}

.search-box {
    display: flex;
    margin-bottom: 30px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.search-input {
    flex-grow: 1;
    padding: 12px 15px;
    font-size: 16px;
    border: none;
    outline: none;
}

.suggestions-button, .search-button {
    padding: 12px 20px;
    font-size: 16px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color var(--transition-speed);
}

.suggestions-button:hover, .search-button:hover {
    background-color: #3367d6;
}

.results-area {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
}

.response-section, .links-section, .savioz-section {
    background-color: white;
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    transition: transform var(--transition-speed);
}

.response-section:hover, .links-section:hover, .savioz-section:hover {
    transform: translateY(-5px);
}

h2 {
    font-size: 20px;
    margin-bottom: 15px;
    color: var(--primary-color);
}

.loading-spinner {
    text-align: center;
    font-size: 24px;
    animation: spin 1s linear infinite;
}

.refresh-button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    float: right;
    color: var(--primary-color);
    transition: transform var(--transition-speed);
}

.refresh-button:hover {
    transform: rotate(180deg);
}

.result-item {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
}

.result-item:last-child {
    border-bottom: none;
}

.result-item a {
    color: var(--link-color);
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    transition: color var(--transition-speed);
}

.result-item a:hover {
    color: var(--link-hover-color);
}

.result-item p {
    color: #5f6368;
    font-size: 14px;
    margin-top: 5px;
}




@media (max-width: 968px) {
    .results-area {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 768px) {
    .results-area {
        grid-template-columns: 1fr;
    }
    .search-box {
        flex-direction: column;
    }
    .search-input, .suggestions-button, .search-button {
        width: 100%;
        border-radius: var(--border-radius);
        margin-bottom: 10px;
    }
    nav {
        flex-wrap: wrap;
    }
    nav button, nav select {
        margin-bottom: 10px;
    }
}

.myImage {
    height: 150px;
    display: block;  /* Change from inline to block */
    margin: 0 auto;  /* Center horizontally */
    object-fit: contain;  /* Maintain aspect ratio */
}

/* If you want to center the image vertically within a container as well, 
   you can wrap it in a div and use flexbox: */

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;  /* Adjust this value as needed */
}

