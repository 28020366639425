/* WaitingList.css */
.waiting-list-container {
    min-height: 100vh;
    background-color: #f3f4f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .waiting-list-content {
    max-width: 28rem;
    width: 100%;
    background-color: white;
    padding: 2.5rem;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .waiting-list-title {
    margin-top: 1.5rem;
    text-align: center;
    font-size: 1.875rem;
    font-weight: 800;
    color: #111827;
  }
  
  .waiting-list-subtitle {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .waiting-list-form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .input-container {
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  
  .form-input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    color: #111827;
  }
  
  .form-input:focus {
    outline: none;
    ring: 2px solid #4f46e5;
    border-color: #4f46e5;
  }
  
  .submit-button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    background-color: #4f46e5;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .submit-button:hover {
    background-color: #4338ca;
  }
  
  .submit-button:focus {
    outline: none;
    ring: 2px solid #4f46e5;
    ring-offset: 2px;
  }
  
  .alert {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 0.375rem;
    background-color: #d1fae5;
    border: 1px solid #34d399;
    display: flex;
    align-items: flex-start;
  }
  
  .alert-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #059669;
    margin-right: 0.75rem;
    flex-shrink: 0;
  }
  
  .alert-title {
    font-size: 1rem;
    font-weight: 600;
    color: #065f46;
    margin: 0;
  }
  
  .alert-description {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: #047857;
  }