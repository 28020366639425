:root {
    --primary-color: #3B82F6;
    --secondary-color: #10B981;
    --background-color: #F3F4F6;
    --text-color: #1F2937;
    --sidebar-width: 250px;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    color: var(--text-color);
  }
  
  .saviochat-container {
    display: flex;
    height: 100vh;
    background-color: var(--background-color);
  }
  
  /* Sidebar styles */
  .sidebar {
    width: var(--sidebar-width);
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .sidebar-header {
    padding: 20px;
  }
  
  .sidebar-header h1 {
    font-size: 24px;
    margin: 0;
  }
  
  .sidebar-header p {
    font-size: 14px;
    color: #6B7280;
    margin: 5px 0 0;
  }
  
  .sidebar-nav {
    margin-top: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    color: var(--text-color);
    text-decoration: none;
    transition: background-color 0.2s;
  }
  
  .nav-item:hover, .nav-item.active {
    background-color: var(--background-color);
  }
  
  .nav-item svg {
    margin-right: 15px;
    width: 20px;
    height: 20px;
  }
  
  .add-conversation-button {
    margin: 20px;
    padding: 10px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .add-conversation-button svg {
    margin-right: 10px;
  }
  
  /* Chat area styles */
  .chat-area {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .model-selector {
    position: relative;
  }
  
  .model-button {
    display: flex;
    align-items: center;
    background-color: var(--background-color);
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .model-button:hover {
    background-color: #E5E7EB;
  }
  
  .model-button svg {
    margin-right: 5px;
  }
  
  .model-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .model-option {
    display: block;
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .model-option:hover {
    background-color: var(--background-color);
  }
  
  .add-bot-button {
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .add-bot-button:hover {
    background-color: #0E9F6E;
  }
  
  .add-bot-button svg {
    margin-right: 5px;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #6B7280;
    transition: color 0.2s;
  }
  
  .close-button:hover {
    color: var(--text-color);
  }
  
  /* Messages container */
  .messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }
  
  .message {
    display: flex;
    margin-bottom: 15px;
  }
  
  .message.user {
    justify-content: flex-end;
  }
  
  .message-content {
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 15px;
    background-color: var(--primary-color);
    color: white;
  }
  
  .message.user .message-content {
    background-color: white;
    color: var(--text-color);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Input area */
  .input-area {
    display: flex;
    padding: 20px;
    background-color: white;
    border-top: 1px solid #E5E7EB;
  }
  
  .input-area input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #E5E7EB;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .input-area input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }
  
  .send-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .send-button:hover {
    background-color: #2563EB;
  }
  
  /* New styles for double chat and interconnect views */
  .double-chat-view, .interconnect-view {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  
  .double-chat-view > div, .interconnect-view > div {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    border-right: 1px solid #E5E7EB;
  }
  
  .double-chat-view > div:last-child, .interconnect-view > div:last-child {
    border-right: none;
  }
  