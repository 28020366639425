.response-section {
  background-color: #e6f3ff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.response-section h2 {
  color: #0066cc;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 600;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  animation: rotate 2s linear infinite;
}

.spinner:before,
.spinner:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 5px;
  animation: pulse 2s linear infinite;
}

.spinner:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
  animation-delay: 1s;
}

@keyframes rotate {
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0%, 100% { transform: scale(0.5); }
  50% { transform: scale(1); }
}

.ai-response {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 20px;
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  to { opacity: 1; }
}

.continue-button {
  background-color: #222;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.continue-button:hover {
  background-color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.continue-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.continue-button-icon {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.continue-button:hover .continue-button-icon {
  transform: translateX(3px);
}
.disclaimer {
  font-size: 12px;
  color: #666;
  font-style: italic;
  margin-top: 10px;
}