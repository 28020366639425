.savioz-landing-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: Arial, sans-serif;
  }
  
  .header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .main-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1e40af;
    margin-bottom: 1rem;
  }
  
  .subtitle {
    font-size: 1.25rem;
    color: #4b5563;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: 600;
    color: #1e40af;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .features-roi-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 4rem;
  }
  
  .feature-list {
    list-style-type: none;
    padding: 0;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .feature-icon {
    color: #10b981;
    margin-right: 0.5rem;
  }
  
  .roi-calculator {
    background-color: #f3f4f6;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .calculator-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e40af;
    margin-bottom: 1rem;
  }
  
  .calculator-inputs {
    display: grid;
    gap: 1rem;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .input-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
  }
  
  .roi-result {
    margin-top: 1.5rem;
    text-align: center;
  }
  
  .result-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #10b981;
  }
  
  .how-it-works-section {
    margin-bottom: 4rem;
  }
  
  .steps-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  
  .step-card {
    border: 1px solid #e5e7eb;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .step-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .cta-section {
    background-color: #e0f2fe;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .cta-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .form-row input,
  .form-row select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
  }
  
  .cta-button {
    width: 100%;
    background-color: #2563eb;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #1d4ed8;
  }
  
  .button-icon {
    margin-left: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .features-roi-section,
    .steps-container,
    .form-row {
      grid-template-columns: 1fr;
    }
  }