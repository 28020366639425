.payment-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
 
  
  .payment-card {
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .card-header {
    background-color: #007bff;
    color: #ffffff;
    padding: 15px;
  }
  
  .card-header h2 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .radio-group {
    margin-bottom: 20px;
  }
  
  .radio-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .radio-item input[type="radio"] {
    margin-right: 10px;
  }
  
  .radio-item label {
    cursor: pointer;
  }
  
  .payment-details {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .payment-details h3 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #333;
  }
  
  .payment-details p {
    margin-bottom: 15px;
    color: #666;
  }
  
  .purchase-button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .purchase-button:hover {
    background-color: #218838;
  }