.links-section {
  font-family: Arial, sans-serif;
  max-width: 652px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.links-section h2 {
  color: #1a0dab;
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.refresh-button {
  background-color: transparent;
  border: none;
  color: #4285f4;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.refresh-button:hover {
  background-color: #f1f3f4;
}

.info {
  color: #006621;
  font-size: 14px;
  margin-bottom: 20px;
}

.result-item {
  margin-bottom: 25px;
}

.result-title {
  color: #1a0dab;
  font-size: 18px;
  text-decoration: none;
  display: block;
  margin-bottom: 3px;
}

.result-title:hover {
  text-decoration: underline;
}

.result-url {
  color: #006621;
  font-size: 14px;
  margin-bottom: 3px;
}

.result-snippet {
  color: #545454;
  font-size: 14px;
  line-height: 1.58;
  margin: 0;
}